import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }),
};

export interface ItemImage {
  url: string;
  uid: string;
  item_uid: string;
  main: boolean;
  metadata: {
    raw_location: Record<string, unknown>; // fields.Dict
    cdn_urls: Record<string, unknown>; // fields.Dict
    edit: string;
  };
}

type HttpResponse<T> = { result: 'error'; message: string } | ({ result: 'OK' } & T);

type GetItemImagesHttpResponse = HttpResponse<{ images: ItemImage[] }>;
type GetImageHttpResponse = HttpResponse<{ image: ItemImage }>;
type EditRejectedImageReasonHttpResponse = HttpResponse<void>;

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private http: HttpClient) {}

  getItemImages(itemUid: string) {
    return this.http.get<GetItemImagesHttpResponse>(`${environment.imageServiceUrl}/runway/items/${itemUid}/images`, httpOptions).pipe(
      map((response) => {
        if (response.result === 'error') {
          console.error(response.message);
          return [];
        }
        return response.images;
      }),
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          throw error;
        }
        if (error.status === 0) {
          console.error('Network error');
        } else {
          console.error(error);
        }
        return of([]);
      }),
    );
  }

  getImage(imgUid: string) {
    return this.http.get<GetImageHttpResponse>(`${environment.imageServiceUrl}/runway/images/${imgUid}`, httpOptions).pipe(
      map((response) => {
        if (response.result === 'error') {
          console.error(response.message);
          return [];
        }
        return response.image;
      }),
      catchError((error) => {
        if (!(error instanceof HttpErrorResponse)) {
          throw error;
        }
        if (error.status === 0) {
          throw new Error('Network error');
        } else {
          throw new Error(error.message);
        }
      }),
    );
  }

  editRejectedImageReason(itemUid: string, imageUid: string, reason: string) {
    return this.http.post<EditRejectedImageReasonHttpResponse>(
      `${environment.imageServiceUrl}/runway/items/${itemUid}/images/${imageUid}/edit-reason`,
      { reason },
      httpOptions,
    );
  }
}
