import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '@app/auth';
import {
  ClientService,
  ItemService,
  LookService,
  ModalService,
  RuleViolationService
} from '@app/core';
import { Category, Client, Item } from '@app/core/store';
import { NotificationType, NotificationObject } from '@app/shared/notification/notificationObject';

import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'component-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.sass']
})
export class ItemComponent implements OnInit {
  @Input() dropped: boolean;
  @Input() inCreate: boolean;
  @Input() item: Item = null;
  // Use for cached looks
  @Input() pdpItem: Item = null;
  @Input() replacedItem: Item = null;
  // End use for cached looks
  @Input() isOnSale: boolean;
  @Input() lookUid?: string;
  @Input() outOfStock: boolean;
  @Input() pinnedLook: boolean;
  @Input() featureLook: boolean;

  @Output() itemDeleted = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<string>();
  @Output() onAddProduct = new EventEmitter<any>();
  @Output() onOpenModal = new EventEmitter<string>();
  @Output() onAddItemList = new EventEmitter<string>();

  preRelease = false;
  needsReview = false;
  isAttVisible = false;
  isAdmin = false;

  category: Category;
  client: Client;
  genderLabel: string = '';
  genderWarning: boolean = false;
  itemFacets: any = {};
  kidsProduct: boolean = false;
  productTooltip: string = '';
  productTooltipKids: string = 'Kids ';
  productTooltipGender: string = '';
  productTooltipAgeRange: string = '';
  ruleViolation: boolean = false;
  // Hardcode VS feature for VS client
  vsClient: boolean;
  // Hardcode CTL feature for CTL client
  ctlClient: boolean;

  // Pinned item values
  pinnedItemUid: string = null;
  isPinnedItem: boolean = false;

  // PDP Item / Replaced Item in Cached Look values
  isSwappedItem: boolean = false;

  // Loader values
  showLoader: boolean = false;

  notification = new NotificationObject();
  notificationType = NotificationType;

  private pinItemSubscription: ISubscription;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
    private itemService: ItemService,
    private lookService: LookService,
    private modalService: ModalService,
    private ruleViolationService: RuleViolationService
  ) {
    this.ruleViolationService.ruleViolation$.subscribe(violation => {
      if (violation) {
        this.ruleViolation = violation;
      } else {
        this.ruleViolation = false;
      }
    });

    this.pinItemSubscription = this.itemService.pinItem$.subscribe(itemUid => {
      this.pinnedItemUid = itemUid;
      if (this.item.uid === this.pinnedItemUid) {
        this.isPinnedItem = true;
      }
    });
  }

  ngOnInit(): void {
    // Used for Cached Looks; if PDP item is passed, use as item
    if (this.pdpItem) {
      this.item = this.pdpItem;
      // Only render swapped item UI if PDP item and Replaced item are different
      if (this.replacedItem && (this.replacedItem.uid !== this.pdpItem.uid)) {
        this.isSwappedItem = true;
      }
    }

    this.renderItemInfo(this.item);
    // Add Look UID to item if it exists
    if (this.lookUid) {
      this.item['lookUid'] = this.lookUid;
    }
    // Determine user role for UI
    if (this.auth.getRole() === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.clientService.getSelectedClient().subscribe(client => {
      this.client = client;

      if (this.client.name.includes('Lululemon') || this.client.name.includes('Gap') || this.client.name.includes('Banana Republic JP') || this.client.name.includes('Rent the Runway') || this.client.name.includes('Lands End US')) {
        this.client['pinnedLooksEnabled'] = true;
      }

      // Check for VS flag
      if (this.dropped) {
        this.vsClient = client.visuallySimilarEnabled;
      }

      // Hardcode CTL feature for CTL Client
      this.ctlClient = client.runwayCtlEnabled;
    });

    this.isPinnedItem = this.item.isPinned;
  }

  itemBanner(item: Item): void {
    if (item.preRelease) {
      this.preRelease = true
    }
    else if (item.stock === 0) {
      this.outOfStock = true;
    } else if (item.flagged) {
      this.needsReview = true;
    } else if (item.sale) {
      this.isOnSale = true;
    } else {
      this.outOfStock = false;
      this.needsReview = false;
      this.isOnSale = false;
    }
  }

  removeItem(itemUid: string): void {
    this.onRemove.emit(itemUid);
    if (itemUid === this.pinnedItemUid) {
      this.itemService.pinToLook(null);
    }
  }

  addItem(item: Item): void {
    if (this.ruleViolation) {
      this.notification.display(this.notificationType.notice, 'Please resolve the rule violation before adding more products.');
    } else {
      this.itemService.addToCanvas(item);
    }
  }

  openModal(event: MouseEvent): void {
    // If PDP and Replaced items via cached look on Snapshot tab, use Multi Item Modal
    if (this.pdpItem && this.replacedItem && this.isSwappedItem) {
      this.modalService.publishMulti([this.pdpItem, this.replacedItem]);
      // Open modal on PDP item, not Replaced item that appears on hover
      this.modalService.publishItem(this.pdpItem);
    } else {
      this.modalService.publishItem(this.item);
    }
    event.stopPropagation();
  }

  clickItemFlag(): void {
    if (this.needsReview) {
      if (window.confirm('Are you sure you want to unflag this item for review?')) {
        this.updateItemFlag(false);
      }
    } else {
      if (window.confirm('Are you sure you want to flag this item for review?')) {
        this.updateItemFlag(true);
      }
    }
  }

  clickItemPin(): void {
    if (this.pinnedLook && this.isPinnedItem) {
      this.itemService.pinToLook(null);
      this.isPinnedItem = false;
    }
    else if (!this.pinnedLook) {
      this.itemService.pinToLook(this.item.uid);
      this.isPinnedItem = true;
    }
  }

  clickVisuallySimilar(itemUni: string, event: MouseEvent): void {
    this.itemService.publishVsItems(true, itemUni);
    event.stopPropagation();
  }

  clickCompleteTheLook(itemUni: string, event: MouseEvent): void {
    this.lookService.publishCtlLook(true, itemUni);
    event.stopPropagation();
  }

  displayReplacedItem(show: boolean): void {
    if (this.pdpItem && show) {
      this.item = this.replacedItem;
    }
    else if (this.pdpItem && !show) {
      this.item = this.pdpItem;
    }
    this.itemBanner(this.item);
  }

  private updateItemFlag(reviewed: boolean): void {
    this.itemService.editItemFlag(this.item.uid, reviewed).subscribe(response => {
      if (response.ok) {
        this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.uid + '.');
        this.renderItemInfo(response.item);
      } else {
        console.error(response.error);
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      }
    });
  }

  private renderItemInfo(data: Item): void {
    // Update item
    this.item = data;
    // Set all item facets into object for reference on template
    data.facets.forEach(facet => {
      this.itemFacets[facet['name']] = facet.items[0];
    });
    // Render item banner
    this.itemBanner(data);
  }

  trashItem(): void {
    if (window.confirm('Are you sure you want to delete this item?')) {
      this.showLoader = true;
      this.deleteItem();
    }
  }

  private deleteItem(): void {
    this.itemService.deleteItem(this.item.uid).subscribe(response => {
      if (response.ok) {
        this.itemService.removeItemFromAppStore(this.item.uid);
        this.notification.display(this.notificationType.okay, 'Successfully Deleted Item.');
        this.itemDeleted.emit(this.item.uid);
        this.showLoader = false;
      }
    }, error => {
      console.log(error);
      this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      this.showLoader = false;
    });
  }

  ngOnDestroy(): void {
    this.pinItemSubscription.unsubscribe();
  }
}
